import AboutUs from './components/AboutUs';
import Footer from './components/Footer';
import Faq from './components/Faq';
import CallToAction from './components/CallToAction';
import NavbarLayout from './pages/NavbarLayout';
import MainContentLayout from './pages/MainContentLayout';
import { Route, Routes } from 'react-router-dom';

export const App = () => {
  return (
    <>
      <NavbarLayout />
      <MainContentLayout />
      <Routes>
        <Route path="faq" element={<CallToAction />} />
        <Route path="/" element={<AboutUs />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Faq />} />
      </Routes>
      <Routes>
        <Route path="/" element={<CallToAction />} />
      </Routes>
      <Footer />
    </>
  );
};
