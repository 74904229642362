import { useTranslation } from 'react-i18next';
import { useFAQ } from '../hook/useFAQ';
import { IndividualFaq } from './IndividualFaq';

const TabsFaq = () => {
  const {t} = useTranslation()
  const { 
    smallFAQInvestor,
    smallFAQCompanies,
    smallFAQShareholders,
    smallFAQTech
  } = useFAQ()

  return (
    <IndividualFaq 
      options={[
        { tab: t('investors'), questions: smallFAQInvestor },
        { tab: t('companies'), questions: smallFAQCompanies },
        { tab: t('shareholders'), questions: smallFAQShareholders },
        { tab: t('tech'), questions: smallFAQTech },
      ]}
    />
  );
};

export default TabsFaq;