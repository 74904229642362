import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SUPPORTED_LANGUAGES } from '../data/constants'

const FLAG = {
  es: 'mx',
  en: 'us'
}

export const LanguagePicker = () => {
  const { t, i18n } = useTranslation()
  const [openOptions, setOpenOptions] = useState(false)
  const { language } = i18n

  useEffect(() => {
    const userConfigLanguage = localStorage.getItem('user_config_language')
    if (userConfigLanguage) {
      void i18n.changeLanguage(userConfigLanguage)
    }
  }, [])

  const toggleOptions = () => {
    setOpenOptions(!openOptions)
  }

  const changeLanguage = lang => {
    if(lang !== language && SUPPORTED_LANGUAGES.includes(lang)){
      void i18n.changeLanguage(lang)
      localStorage.setItem('user_config_language', lang)
    }
    toggleOptions()
  }

  return (
    <Container onMouseEnter={toggleOptions} onMouseLeave={toggleOptions}>
      <span className={`fi fi-${FLAG[language]}`} onClick={toggleOptions} /> 
      {openOptions && (
        <section>
          {SUPPORTED_LANGUAGES.map(lang => (
            <div className='option-container' key={lang} onClick={() => changeLanguage(lang)}>
              <span
                className={`fi fi-${FLAG[lang]}`}
                onClick={changeLanguage}
                value={lang}
              />
              <h2>{ t(lang) }</h2>
            </div>
            
          ))}
        </section>
      )}
    </Container>
  )
}

const Container = styled.div`
  max-height: 24px;
  cursor: pointer;
  transition: border-radius 0.2s ease;

  .fi {
    font-size: 17px;
  }

  h2 {
    font-size: 14px;
    font-weight: 400;
  }

  section {
    transition: all 0.3s ease;
    position: absolute;
    margin-top: 0px;
    background-color: #032A45;
    color: white; 
    border-radius: 0 4px 4px 4px;
    padding: 4px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    z-index: 1;
    gap: 1rem;
  }

  .option-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 6px;
    gap: 0.5rem;

    :hover {
      color: #FFAD62;
    }

    :last-child {
      margin-top: 0;
    }
  }

`
