import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Card, CardTitle } from '../styled-components';

// stepNumber title e image son las props necesarias para crear una cardsData, vienen de data card en la carpeta data
const CardSteps = ({ stepNumber, title, image, number }) => {
  return (
    <Card
      as={motion.div}
      initial={{ opacity: 0, translateY: -50 }}
      whileInView={{ opacity: 1, translateY: 0 }}
      transition={{
        type: 'spring',
        duration: `${stepNumber}` * 0.9,
        delay: `${stepNumber}` * 0.2,
      }}
      viewport={{ once: true }}
    >
      <CardHeader>
        <img alt='' src={number} />
        <CardTitle>{title}</CardTitle>
      </CardHeader>
      <div style={{ border: '1px solid #032A45' }}>
        <img src={image} alt="" />
      </div>
    </Card>
  );
};

const CardHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    min-width: 60px !important;
    border: none !important;
  }
`

export default CardSteps;
