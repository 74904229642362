import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

const Navbar = styled.nav`
  align-items: center;
  background-color: ${props =>
    props.active ? colors.background : 'transparent'};
  display: flex;
  gap: 2rem;
  font-weight: 400;
  justify-content: space-around;
  padding: 20px 10%;
  position: fixed;
  box-shadow: ${props => props.active && 'rgba(0, 0, 0, 0.15) 0px 3px 3px 0px'};
  width: 100%;
  z-index: 10000000;
  transition: all 0.3s ease-in;
  
  img {
    height: 68px;
    max-width: max-content;
    margin: 8px 0 8px 0;

    @media (max-width: 1600px) {
      height: 60px;
    }

    @media (max-width: 1260px) {
      height: 45px;
    }

    @media (max-width: ${breakpoints.tablet}) {
      height: 42px;
    }

    @media (max-width: ${breakpoints.mobile}) {
      height: 30px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 1rem 1%;
  }
  @media (max-width: ${breakpoints.mobile}) {
    padding: 1rem 5%;
  }
`;

export const NavbarLinks = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  
  @media (max-width: ${breakpoints.mobile}) {
    gap: 1rem;
    display: none;
  }
`;

export default Navbar;
