import { useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

export const useFAQ = () => {
  const {t, i18n: {language}} = useTranslation()

  const smallFAQInvestor = useCallback( [
    {
      title: t('faq.investor.questions.how_do_i_start'),
      content: t('faq.investor.responses.how_do_i_start'),
      id: 'panel1-header-inversionistas',
      ariaControls: 'panel1-content-inversionistas',
      panel: 'panel1',
    },
    {
      title: t('faq.investor.questions.how__buy_shares'),
      content: t('faq.investor.responses.how__buy_shares'),
      id: 'panel2-header-inversionistas',
      ariaControls: 'panel2-content-inversionistas',
      panel: 'panel2',
    },
    {
      title: t('faq.investor.questions.how_know_i_am_qualified'),
      content: t('faq.investor.responses.how_know_i_am_qualified'),
      id: 'panel3-header-inversionistas',
      ariaControls: 'panel3-content-inversionistas',
      panel: 'panel3',
    },
  ], [language])

  const fullFAQInvestor = useCallback([
    ...smallFAQInvestor,
    {
      title: t('faq.investor.questions.how_prove_i_am_qualified'),
      content: t('faq.investor.responses.how_prove_i_am_qualified'),
      id: 'panel4-header-inversionistas',
      ariaControls: 'panel4-content-inversionistas',
      panel: 'panel4',
    },
    {
      title:  t('faq.investor.questions.cost_of_buying'),
      content:  t('faq.investor.responses.cost_of_buying'),
      id: 'panel5-header-inversionistas',
      ariaControls: 'panel5-content-inversionistas',
      panel: 'panel5',
    },
    {
      title:  t('faq.investor.questions.know_my_identity'),
      content:  t('faq.investor.responses.know_my_identity'),
      id: 'panel6-header-inversionistas',
      ariaControls: 'panel6-content-inversionistas',
      panel: 'panel6',
    },
    {
      title:  t('faq.investor.questions.how_receive_my_shares'),
      content:  t('faq.investor.responses.how_receive_my_shares'),
      id: 'panel7-header-inversionistas',
      ariaControls: 'panel7-content-inversionistas',
      panel: 'panel7',
    },
  ], [language])

  const smallFAQCompanies = useCallback([
    {
      title: t('faq.companies.questions.what_legal_structure'),
      content: t('faq.companies.responses.what_legal_structure'),
      id: 'panel1-header-empresas',
      ariaControls: 'panel1-content-empresas',
      panel: 'panel1',
    },
    {
      title:  t('faq.companies.questions.how_protect_information'),
      content: t('faq.companies.responses.how_protect_information'),
      id: 'panel2-header-empresas',
      ariaControls: 'panel2-content-empresas',
      panel: 'panel2',
    },
    {
      title: t('faq.companies.questions.how_price_shares'),
      content: t('faq.companies.responses.how_price_shares'),
      id: 'panel3-header-empresas',
      ariaControls: 'panel3-content-empresas',
      panel: 'panel3',
    },
  ], [language]);

  const fullFAQCompanies = useCallback([
    ...smallFAQCompanies,
    {
      title: t('faq.companies.questions.can_i_decide_who_sells'),
      content: t('faq.companies.responses.can_i_decide_who_sells'),
      id: 'panel11-header-empresas',
      ariaControls: 'panel11-content-empresas',
      panel: 'panel11',
    },
    {
      title: t('faq.companies.questions.can_i_decide_who_buys'),
      content: t('faq.companies.responses.can_i_decide_who_buys'),
      id: 'panel12-header-empresas',
      ariaControls: 'panel12-content-empresas',
      panel: 'panel12',
    },
    {
      title: t('faq.companies.questions.who_can_access'),
      content: t('faq.companies.responses.who_can_access'),
      id: 'panel13-header-empresas',
      ariaControls: 'panel13-content-empresas',
      panel: 'panel13',
    },
    {
      title: t('faq.companies.questions.best_interest'),
      content: t('faq.companies.responses.best_interest'),
      id: 'panel14-header-empresas',
      ariaControls: 'panel14-content-empresas',
      panel: 'panel14',
    },
    {
      title: t('faq.companies.questions.pay_to_register'),
      content: t('faq.companies.responses.pay_to_register'),
      id: 'panel15-header-empresas',
      ariaControls: 'panel15-content-empresas',
      panel: 'panel15',
    },
    {
      title: t('faq.companies.questions.kind_of_transactions'),
      content: t('faq.companies.responses.kind_of_transactions'),
      id: 'panel16-header-empresas',
      ariaControls: 'panel16-content-empresas',
      panel: 'panel16',
    },
    {
      title: t('faq.companies.questions.should_i_fiscally'),
      content: t('faq.companies.responses.should_i_fiscally'),
      id: 'panel17-header-empresas',
      ariaControls: 'panel17-content-empresas',
      panel: 'panel17',
    },
    {
      title: t('faq.companies.questions.preferred_provider'),
      content: t('faq.companies.responses.preferred_provider'),
      id: 'panel18-header-empresas',
      ariaControls: 'panel18-content-empresas',
      panel: 'panel18',
    },
  ], [language])

  const smallFAQShareholders = useCallback([
    {
      title: t('faq.shareholders.questions.can_i_sell'),
      content: t('faq.shareholders.responses.can_i_sell'),
      id: 'panel1-header-empresas',
      ariaControls: 'panel1-content-empresas',
      panel: 'panel1',
    },
    {
      title:  t('faq.shareholders.questions.how_price'),
      content: t('faq.shareholders.responses.how_price'),
      id: 'panel2-header-empresas',
      ariaControls: 'panel2-content-empresas',
      panel: 'panel2',
    },
    {
      title:  t('faq.shareholders.questions.tax_implication'),
      content: 
        <Trans 
          i18nKey='faq.shareholders.responses.tax_implication'
          components={{br: <br />}}
        />,
      id: 'panel3-header-empresas',
      ariaControls: 'panel3-content-empresas',
      panel: 'panel3',
    },
  ], [language]);

  const fullFAQShareholders = useCallback([
    {
      title: t('faq.shareholders.questions.what_is_BMP'),
      content: t('faq.shareholders.responses.what_is_BMP'),
      id: 'panel22-header-accionistas',
      ariaControls: 'panel22-content-accionistas',
      panel: 'panel22',
    },
    ...smallFAQShareholders,
    {
      title: t('faq.shareholders.questions.what_is_the_cost'),
      content: t('faq.shareholders.responses.what_is_the_cost'),
      id: 'panel23-header-accionistas',
      ariaControls: 'panel23-content-accionistas',
      panel: 'panel23',
    },
    {
      title: t('faq.shareholders.questions.how_long'),
      content: t('faq.shareholders.responses.how_long'),
      id: 'panel24-header-accionistas',
      ariaControls: 'panel24-content-accionistas',
      panel: 'panel24',
    },
    {
      title: t('faq.shareholders.questions.how_receive_money'),
      content: t('faq.shareholders.responses.how_receive_money'),
      id: 'panel25-header-accionistas',
      ariaControls: 'panel25-content-accionistas',
      panel: 'panel25',
    },
    {
      title:t('faq.shareholders.questions.minimum_transactions'),
      content:t('faq.shareholders.responses.minimum_transactions'),
      id: 'panel26-header-accionistas',
      ariaControls: 'panel26-content-accionistas',
      panel: 'panel26',
    },
    {
      title: t('faq.shareholders.questions.know_if_company_register'),
      content: t('faq.shareholders.responses.know_if_company_register'),
      id: 'panel27-header-accionistas',
      ariaControls: 'panel27-content-accionistas',
      panel: 'panel27',
    },
    {
      title: t('faq.shareholders.questions.what_can_i_do'),
      content: t('faq.shareholders.responses.what_can_i_do'),
      id: 'panel28-header-accionistas',
      ariaControls: 'panel28-content-accionistas',
      panel: 'panel28',
    },
  ], [language])

  const smallFAQTech = useCallback([
    {
      title: t('faq.tech.questions.what_is_blockchain'),
      content: t('faq.tech.responses.what_is_blockchain'),
      id: 'panel1-header-inversionistas',
      ariaControls: 'panel1-content-inversionistas',
      panel: 'panel1',
    },
    {
      title: t('faq.tech.questions.how_make_a_purchase'),
      content: (
        <Trans
          i18nKey="faq.tech.responses.how_make_a_purchase"
          components={{em: <em />}}
        />
      ),
      id: 'panel2-header-inversionistas',
      ariaControls: 'panel2-content-inversionistas',
      panel: 'panel2',
    },
    {
      title: t('faq.tech.questions.what_blockchain_use'),
      content: t('faq.tech.responses.what_blockchain_use'),
      id: 'panel3-header-inversionistas',
      ariaControls: 'panel3-content-inversionistas',
      panel: 'panel3',
    },
  ], [language]); 

  const fullFAQTech = useCallback([
    smallFAQTech[0],
    {
      title: t('faq.tech.questions.how_bc_works'),
      content: t('faq.tech.responses.how_bc_works'),
      id: '31-header-accionistas',
      ariaControls: 'panel31-content-accionistas',
      panel: 'panel31',
    },
    {
      title: t('faq.tech.questions.advantages_bc'),
      content:  
        <>
        <span>{ t('faq.tech.responses.advantages_bc.one') }</span>
        <>
          <li>{ t('faq.tech.responses.advantages_bc.two') }</li>
          <li>{ t('faq.tech.responses.advantages_bc.three') }</li>
          <li>{ t('faq.tech.responses.advantages_bc.four') }</li>
        </>
      </>,
      id: 'panel0header-accionistas',
      ariaControls: 'panel0content-accionistas',
      panel: 'panel0',
    },
    {
      title: t('faq.tech.questions.what_is_tokenization'),
      content: t('faq.tech.responses.what_is_tokenization'),
      id: 'panel32-header-accionistas',
      ariaControls: 'panel32-content-accionistas',
      panel: 'panel32',
    },
    {
      title:  t('faq.tech.questions.what_is_token'),
      content: t('faq.tech.responses.what_is_token'),
      id: 'panel33-header-accionistas',
      ariaControls: 'panel33-content-accionistas',
      panel: 'panel33',
    },
    {
      title: t('faq.tech.questions.advantages'),
      content: (
        <>
          <span>{ t('faq.tech.responses.advantages.one') }</span>
          <>
            <li>{ t('faq.tech.responses.advantages.two') }</li>
            <li>{ t('faq.tech.responses.advantages.three') }</li>
          </>
          <span>{ t('faq.tech.responses.advantages.four') }</span>
        </>
      ),
      id: 'panel40-header-accionistas',
      ariaControls: 'panel40-content-accionistas',
      panel: 'panel40',
    },
    smallFAQTech[1],
    {
      title: t('faq.tech.questions.what_is_a_wallet'),
      content: (
        <>
          <span>{ t('faq.tech.responses.what_is_a_wallet') }</span>
        </>
      ),
      id: 'panel35-header-accionistas',
      ariaControls: 'panel35-content-accionistas',
      panel: 'panel35',
    },
    smallFAQTech[2],
  ], [language])

  return {
    smallFAQInvestor,
    smallFAQCompanies,
    smallFAQShareholders,
    smallFAQTech,
    fullFAQInvestor,
    fullFAQCompanies,
    fullFAQShareholders,
    fullFAQTech
  }
}
