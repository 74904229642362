import { useState } from 'react';
import { FaqAnswer, FaqQuestions } from '../../styled-components';
import { ContainerGeneric } from '../../styled-components/ContainerGeneric';
import { colors } from '../../Theme/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';

import { BlueTitle } from './styled-components/BlueTitle';
import { useTranslation } from 'react-i18next';
import { useFAQ } from '../../hook/useFAQ';
import { FaqTitleBlue } from '../../styled-components/FaqContainer';

const { lightBlue, background } = colors;

const FaqAlone = () => {
  const [expanded, setExpanded] = useState(false);
  const { fullFAQInvestor, fullFAQCompanies, fullFAQShareholders, fullFAQTech } = useFAQ()
  const {t} = useTranslation()
  const handleChange = (isExpanded, panel) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <ContainerGeneric style={{ backgroundColor: `${colors.lightGray}` }}>
      <FaqTitleBlue>
      {t('faq.resolve_doubts')} {' '}<span>{t('faq.about_BMP')}</span>
      </FaqTitleBlue>
      <BlueTitle>{t('about_us.for_investors')}</BlueTitle>
      <div>
        {fullFAQInvestor.map(inversionista => {
          return (
            <Accordion
              expanded={expanded === inversionista.panel}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, inversionista.panel)
              }
              style={{ marginBottom: '1rem', color: background }}
              key={inversionista.id}
            >
              <AccordionSummary
                id={inversionista.id}
                aria-controls={inversionista.ariaControls}
                expandIcon={<ExpandMoreIcon style={{ fill: lightBlue }} />}
              >
                <Typography>
                  <FaqQuestions>{inversionista.title}</FaqQuestions>
                </Typography>
              </AccordionSummary>
              <FaqAnswer>{inversionista.content}</FaqAnswer>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          );
        })}
      </div>

      <BlueTitle>{t('about_us.for_companies')}</BlueTitle>
      <div>
        {fullFAQCompanies.map(empresa => {
          return (
            <Accordion
              expanded={expanded === empresa.panel}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, empresa.panel)
              }
              style={{ marginBottom: '1rem', color: background }}
              key={empresa.id}
            >
              <AccordionSummary
                id={empresa.id}
                aria-controls={empresa.ariaControls}
                expandIcon={<ExpandMoreIcon style={{ fill: lightBlue }} />}
              >
                <Typography>
                  <FaqQuestions>{empresa.title}</FaqQuestions>
                </Typography>
              </AccordionSummary>
              <FaqAnswer>{empresa.content}</FaqAnswer>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          );
        })}
      </div>

      <BlueTitle>{t('for_shareholders')}</BlueTitle>

      <div>
        {fullFAQShareholders.map(accionista => {
          return (
            <Accordion
              expanded={expanded === accionista.panel}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, accionista.panel)
              }
              style={{ marginBottom: '1rem', color: background }}
              key={accionista.id}
            >
              <AccordionSummary
                id={accionista.id}
                aria-controls={accionista.ariaControls}
                expandIcon={<ExpandMoreIcon style={{ fill: lightBlue }} />}
              >
                <Typography>
                  <FaqQuestions>{accionista.title}</FaqQuestions>
                </Typography>
              </AccordionSummary>
              <FaqAnswer>{accionista.content}</FaqAnswer>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          );
        })}
      </div>

      <BlueTitle>{t('tech')}</BlueTitle>
      <div>
        {fullFAQTech.map(tecnologia => {
          return (
            <Accordion
              expanded={expanded === tecnologia.panel}
              onChange={(event, isExpanded) =>
                handleChange(isExpanded, tecnologia.panel)
              }
              style={{ marginBottom: '1rem', color: background }}
              key={tecnologia.id}
            >
              <AccordionSummary
                id={tecnologia.id}
                aria-controls={tecnologia.ariaControls}
                expandIcon={<ExpandMoreIcon style={{ fill: lightBlue }} />}
              >
                <Typography>
                  <FaqQuestions>{tecnologia.title}</FaqQuestions>
                </Typography>
              </AccordionSummary>
              <FaqAnswer>{tecnologia.content}</FaqAnswer>
              <AccordionDetails></AccordionDetails>
            </Accordion>
          );
        })}
      </div>
    </ContainerGeneric>
  );
};

export default FaqAlone;
