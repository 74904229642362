import { Route, Routes } from 'react-router-dom';
import FaqAlone from '../components/faqAlone/FaqAlone';
import RegisterEmitter from '../components/forms/RegisterEmitter';
import RegisterInvestor from '../components/forms/RegisterInvestor';
import MainContent from '../components/MainContent';

const MainContentLayout = () => {
  return (
    <Routes>
      <Route path="/" element={<MainContent />}></Route>
      <Route path="faq" element={<FaqAlone />} />
      <Route path="empresa" element={<RegisterEmitter />} />
      <Route path="inversionista" element={<RegisterInvestor />} />
    </Routes>
  );
};

export default MainContentLayout;
