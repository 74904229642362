import { Route, Routes } from 'react-router-dom';
import Navbar from '../components/Navbar';
import NavbarOnlyLogo from '../components/NavbarOnlyLogo';

const NavbarLayout = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Navbar />} />
        <Route path="faq" element={<NavbarOnlyLogo />} />
        <Route path="empresa" element={<NavbarOnlyLogo />} />
        <Route path="inversionista" element={<NavbarOnlyLogo />} />
      </Routes>
    </>
  );
};

export default NavbarLayout;
