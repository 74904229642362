import styled from '@emotion/styled';
import { Link } from 'react-router-dom';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

const Button = styled(Link)`
  border-radius: ${colors.borderRadius};
  border: ${colors.border};
  background-color: ${colors.lightBlue};
  color: ${colors.textColor};
  cursor: pointer;
  font-weight: 700;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  z-index: 100;
  transition: background-color 0.3s, color 0.3s ease;
  text-decoration: none;
  z-index: 1000;

  &:hover {
    background-color: ${colors.textColor};
    color: ${colors.lightBlue};
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.5em 1em;
  }
`;

export const ButtonOutline = styled(Button)`
  background-color: ${colors.textColor};
  color: ${colors.lightBlue};

  &:hover {
    background-color: ${colors.lightBlue};
    color: ${colors.textColor};
  }
`;

export const ButtonSubmit = styled.button`
  border-radius: ${colors.borderRadius};
  border: ${colors.border};
  background-color: ${colors.lightBlue};
  color: ${colors.textColor};
  cursor: pointer;
  font-weight: 700;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  transition: background-color 0.3s, color 0.3s ease;
  text-decoration: none;
  margin-top: 2rem;

  &:hover {
    background-color: ${colors.textColor};
    color: ${colors.lightBlue};
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.5em 1em;
  }
`;

export default Button;
