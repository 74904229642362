import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

export const ContainerGeneric = styled.div`
  padding: ${colors.padding};
  padding-top: 10vh;
  padding-bottom: 10vh;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 2rem 10%;
    padding-top: 10vh;
  }
`;
