import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useResponsive } from '../hook/useResponsive';
import { DesktopFAQ } from './FAQ/DesktopFAQ';
import { MobileFAQ } from './FAQ/MobileFAQ';

/*
 options is an array of objects with the following structure:
  [{ 
    tab: string,
    questions: [
      {
        title: string,
        content: string 
      }
    ]
  }}
*/
export const IndividualFaq = ({options = []}) => {
  const [questions, setQuestions] = useState(options[0].questions)
  const [selectedTab, setSelectedTab] = useState(options[0].tab)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [expanded, setExpanded] = React.useState(false);
  const { i18n: {language} } = useTranslation()
  const { isMobileDevice } = useResponsive()

  const handleOpenPanel = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  }

  const isSelected = tab => tab === selectedTab ? 'on' : 'off'

  const onTabClick = (tab, questions, index) => {
    setQuestions(questions)
    setSelectedTab(tab)
    setSelectedTabIndex(index)
  }

  useEffect(() => {
    setQuestions(options[selectedTabIndex].questions)
    setSelectedTab(options[selectedTabIndex].tab)
  }, [language])
  

  return (
    <>
    { isMobileDevice ?
      <MobileFAQ 
        expanded={expanded}
        handleOpenPanel={handleOpenPanel}
        isSelected={isSelected}
        onTabClick={onTabClick}
        options={options}
        questions={questions}
      />
      : <DesktopFAQ 
        expanded={expanded}
        handleOpenPanel={handleOpenPanel}
        isSelected={isSelected}
        onTabClick={onTabClick}
        options={options}
        questions={questions}
      />
    }
    </>
  )
}

