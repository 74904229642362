import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

export const CardSectionContainer = styled.section`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 2rem;
  background-color: ${colors.background};
  padding: 0 10%;
  padding-top: 2%;
  align-items: center;
  position: relative;

  @media (max-width: ${breakpoints.desktop}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding-bottom: 4rem;
    grid-template-columns: 1fr;
  }
`;
export const Card = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  transition: transform 0.3s ease;

  &:hover {
    transform: translateY(-2rem) !important;
  }

  .card-header {
    display: flex;
     
    .card-header__img {
      max-height: 40px !important;
      max-width: 40px !important;
      border: none !important;
    }
  }

  img {
    border: ${colors.lightBlue} solid 2px;
    padding: 8px;
    min-width: max(200px, 100%);
  }

  h3 {
    font-weight: 900;
    font-size: 4.8rem;
    color: ${colors.orange};
  }
`;

export const CardTitle = styled.p`
  font-size: 20px;
  font-weight: 600;
  color: ${colors.textColor};

  @media (max-width: ${breakpoints.largeDesktop}) {
    font-size: 1.6rem;
  }
`;

export const CardDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  bottom: 10%;
  color: ${colors.textColor};
  align-items: flex-start;
  padding: 8rem 10%;
  background-color: #032A45;
  justify-content: space-between;
  p {
    font-size: 20px;
  }

  span {
    font-weight: 700;
  }

  @media (max-width: ${breakpoints.desktop}) {
    bottom: 6%;

    p {
      font-size: 2rem;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    bottom: 4%;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media (max-width: ${breakpoints.mobile}) {
    bottom: 2%;
    padding: 0 2rem;
    flex-direction: column;
    align-items: center;
    text-align: center;

    p {
      font-size: 1.4rem;
    }
  }

  @media (max-width: ${breakpoints.smallMobile}) {
    align-items: center;
    text-align: center;

    p {
      font-size: 1.2rem;
    }
  }
`;

export const CardQuantum = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  align-items: flex-end;
  color: ${colors.textColor};
  padding-top: 5px;
  line-height: 1;
  gap: 0.5em;
  text-align: right;
  width: 100%;
  
  p {
    font-size: 13px;
  }

  @media (max-width: ${breakpoints.desktop}) {
    p {
      font-size: 13px;
    }
  }

  @media (max-width: ${breakpoints.tablet}) {
    padding: 4rem;
    align-items: center;
    p {
      font-size: 11px;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 4rem 0;
    align-items: center;
    p {
      font-size: 9px
    }
  }

  @media (max-width: ${breakpoints.smallMobile}) {
    padding: 2rem;
    align-items: center;

    p {
      font-size: 9px
    }
  }
`;
