import scrollArrow from '../assets/img/icons/arrow.png';
import styled from '@emotion/styled';
import { scrollToTop } from '../utilities/scrollToTop';
import { useEffect, useState } from 'react';

export const Arrow = styled.div`
  z-index: 100;
  transform: rotate(180deg);
  position: fixed;
  right: 2rem;
  bottom: 2rem;
  width: 2rem;
  display: ${props => (props.active ? 'block' : 'none')};

  img {
    transform: rotate(180deg);
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      transform: rotate(180deg) scale(1.1);
    }

    animation-name: arrowAnimation;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes arrowAnimation {
    0% {
      opacity: 0;
      transform: rotate(180deg) scale(0.9) translateY(0);
    }

    100% {
      opacity: 1;
      transform: rotate(180deg) scale(1.1) translateY(-1rem);
    }
  }
`;

const ScrollToTopArrow = () => {
  const [navbarActive, setNavbarActive] = useState(0);

  const changeBackground = () => {
    if (window.scrollY >= 400) {
      setNavbarActive(1);
    } else {
      setNavbarActive(0);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, [navbarActive]);
  return (
    <Arrow active={navbarActive}>
      <img
        onClick={scrollToTop}
        src={scrollArrow}
        alt="arrow to scroll up"
      />
    </Arrow>
  );
};

export default ScrollToTopArrow;
