import {
  StyledFooter,
  FooterCopyright,
  FooterLogo,
  FooterNav,
  FooterSocial,
} from '../styled-components';
import BivaLogo from '../assets/img/logos/logoBivaWhite.svg';
import fbIcon from '../assets/img/icons/fb-icon.png';
import igIcon from '../assets/img/icons/ig-icon.png';
import tkIcon from '../assets/img/icons/tk-icon.png';
import twIcon from '../assets/img/icons/tw-icon.png';
import { motion } from 'framer-motion';
import privacidad from '../assets/pdf/privacy-policy.pdf';
import terminos from '../assets/pdf/terms-and-use.pdf';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const {t} = useTranslation()
  return (
    <StyledFooter>
      <FooterNav>
        <FooterLogo src={BivaLogo} alt='Logo' />

        <div style={{ display: 'flex' }}>
          <motion.a
            href=' https://www.facebook.com/bivamx/'
            target={'_blank'}
            initial={{ opacity: 0, translateY: -50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              duration: 0.3,
              delay: 0.3,
            }}
            viewport={{ once: true }}
          >
            <img src={fbIcon} alt='Facebook Icon' />
          </motion.a>

          <motion.a
            target={'_blank'}
            href='https://www.instagram.com/biva_mx/'
            initial={{ opacity: 0, translateY: -50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              duration: 0.3,
              delay: 0.4,
            }}
            viewport={{ once: true }}
          >
            <img src={igIcon} alt='Instagram Icon' />
          </motion.a>

          <motion.a
            target={'_blank'}
            href='https://www.tiktok.com/@biva_mx'
            initial={{ opacity: 0, translateY: -50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              duration: 0.3,
              delay: 0.5,
            }}
            viewport={{ once: true }}
          >
            <img src={tkIcon} alt='Tik tok Icon' />
          </motion.a>

          <motion.a
            target={'_blank'}
            href='https://twitter.com/BIVAMX'
            initial={{ opacity: 0, translateY: -50 }}
            whileInView={{ opacity: 1, translateY: 0 }}
            transition={{
              duration: 0.3,
              delay: 0.6,
            }}
            viewport={{ once: true }}
          >
            <img src={twIcon} alt='Twitter Icon' />
          </motion.a>
        </div>
      </FooterNav>
      <p style={{ marginBottom: '1rem', lineHeight: 1.9 }}>{ t('disclaimer') }</p>
      <FooterSocial>
        <div>
          <p><strong>©2022 - Bolsa institucional de valores S.A. de C.V.</strong></p>
          <p><strong>{t('rights_reserved')}</strong></p>
        </div>
        <div style={{ display: 'flex', gap: '3rem' }}>
          <a href={privacidad} download={'Privacidad'}>
            <strong>{t('privacy')}</strong>
          </a>
          <a href={terminos} download={'Términos y condiciones'}>
            <strong>{t('terms')}</strong>
          </a>
        </div>
      </FooterSocial>

      <FooterCopyright>
        
      </FooterCopyright>
    </StyledFooter>
  );
};

export default Footer;
