import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

const StyledFooter = styled.footer`
  background-color: ${colors.background};
  width: 100%;
  padding: ${colors.padding};
  padding-top: 4rem;
  color: ${colors.textColor};
  padding-bottom: 4rem;

  @media (max-width: ${breakpoints.mobile}) {
    padding: 4rem 10% 2rem 10%;
  }
`;

export const FooterLogo = styled.img`
  max-width: 25%;

  @media (max-width: ${breakpoints.mobile}) {
      max-width: min-content;
      height: 22px;
      margin-right: 8px;
  }

`;

export const FooterNav = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;

  a {
    transition: all 0.3s ease;

    &:hover {
      transform: translateY(-10px) !important;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    gap: 10px;

    a {
      transition: all 0.3s ease;

      &:hover {
        transform: translateY(0) !important;
      }
    }
    img {
      width: 70%;
    }
  }
`;

export const FooterSocial = styled.div`
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
  justify-content: space-between;
  position: relative;

  a {
    text-decoration: none;
    color: ${colors.textColor};
  }

  @media (max-width: ${breakpoints.mobile}) {
    gap: 1rem;
  }
`;

export const FooterCopyright = styled.div`
  display: grid;
  margin-top: 2rem;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  position: relative;

`;

export default StyledFooter;
