import { MainContainer } from '../styled-components';
import { motion } from 'framer-motion';
import { animationDuration } from '../Theme/animationDuration';
import { StyledButton } from '../styled-components/StyledLink';
import video from '../assets/video/bg.mp4';
import { VideoOverlay } from '../styled-components/MainContainer';
import ScrollToTopArrow from './ScrollToTopArrow';
import { Trans, useTranslation } from 'react-i18next';

const { oneSecond } = animationDuration;

const MainContent = () => {
  const {t} = useTranslation()
  return (
    <MainContainer
      as={motion.main}
      exit={{ opacity: 0, transition: { duration: 1 } }}
    >
      <VideoOverlay>
        <video
          style={{
            width: '100%',
            height: '100%',
            position: 'absolute',
            left: 0,
            top: 0,
            objectFit: 'cover',
            backgroundPosition: 'center',
            overflowY: 'hidden',
          }}
          src={video}
          autoPlay
          loop
          muted
          playsInline
        ></video>
      </VideoOverlay>
      <motion.h1
        initial={{ opacity: 0, translateY: -50 }}
        whileInView={{ opacity: 1, translateY: 0 }}
        transition={{
          duration: oneSecond,
          delay: 0.3,
        }}
        viewport={{ once: true }}
        exit={{ opacity: 0, transition: { duration: 1 } }}
      >
          <p id='h1' style={{display: 'contents'}}>
            <Trans
              i18nKey='main_page.title_one'
              components={{strong: <strong />}}
            />
          </p>
          <p id="h1" style={{display: 'contents'}}>
            &nbsp;{t('main_page.title_three')} {t('main_page.title_four')}
          </p>
      </motion.h1>
      <motion.p
        style={{
          fontWeight: 700,
          textShadow: `1px 1px 1px #000`,
        }}
        initial={{ opacity: 0, translateY: -50 }}
        whileInView={{ opacity: 1, translateY: 0 }}
        transition={{
          duration: oneSecond,
          delay: 0.5,
        }}
        viewport={{ once: true }}
      >
        {t('main_page.subtitle')}
      </motion.p>
      <motion.div
        style={{ marginTop: '1rem', zIndex: '2000000' }}
        initial={{ opacity: 0, translateY: -30 }}
        whileInView={{ opacity: 1, translateY: 0 }}
        transition={{
          duration: oneSecond,
          delay: 0.5,
        }}
        viewport={{ once: true }}
      >
        <StyledButton smooth to="#comoFunciona">
          {t('learn_more')}
        </StyledButton>
      </motion.div>
      <ScrollToTopArrow />
    </MainContainer>
  );
};

export default MainContent;
