const breakpoints = {
  smallMobile: '340px',
  mobile: '576px',
  tablet: '768px',
  desktop: '992px',
  largeDesktop: '1200px',
  largerDesktop: '1400px',
};

export default breakpoints;
