export const DEFAULT_LANGUAGE = 'es'
export const SUPPORTED_LANGUAGES = ['es', 'en']

export const getLanguageResources = () => {
  const resources = {}
  SUPPORTED_LANGUAGES.forEach((language) => {
    resources[language] = {
      translation: require(`../translations/${language}/translation.json`)
    }
  })
  return resources
}
