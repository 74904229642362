import styled from '@emotion/styled';
import breakpoints from '../../../Theme/breakpoints';
import { colors } from '../../../Theme/colors';

export const BlueTitle = styled.h2`
  color: ${colors.background};
  font-weight: 700;
  font-size: 1.8rem;
  margin: 2rem 0 1rem 0;
  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.5rem;
  }
`;
