import { useTranslation } from 'react-i18next';
import card1 from '../assets/img/steps/step1.png';
import card2 from '../assets/img/steps/step2.png';
import card3 from '../assets/img/steps/step3.png';
import card4 from '../assets/img/steps/step4.png';

import num1 from '../assets/img/steps/num1.svg';
import num2 from '../assets/img/steps/num2.svg';
import num3 from '../assets/img/steps/num3.svg';
import num4 from '../assets/img/steps/num4.svg';

export const useCardsData = () => {
  const {t} = useTranslation()
  const cardsData = [
    {
      stepNumber: 1,
      number: num1,
      title: t('about_us.one'),
      image: card1,
    },
    {
      stepNumber: 2,
      number: num2,
      title: t('about_us.two'),
      image: card2,
    },
    {
      stepNumber: 3,
      number: num3,
      title: t('about_us.three'),
      image: card3,
    },
    {
      stepNumber: 4,
      number: num4,
      title: t('about_us.four'),
      image: card4,
    },
  ];
  return cardsData;
};

