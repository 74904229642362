import styled from '@emotion/styled';
import { HashLink as Link } from 'react-router-hash-link';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

export const Menu = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  height: 100vh;
  width: 60%;
  align-items: center;
  justify-content: center;
  transform: ${props =>
    props.menuActive ? 'translate(0)' : 'translate(-50rem)'};
  transition: transform 0.8s ease;

  a {
    font-size: 2rem;
    font-weight: 700;
  }
  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const HamburguerIcon = styled.div`
    display: flex;
    align-items: center;
    gap: 1.5rem;
  @media (min-width: ${breakpoints.mobile}) {
    display: none;
  }
`;

export const StyledLinkMobile = styled(Link)`
  color: ${props => (props.active ? colors.orange : colors.textColor)};
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease;
  font-size: 17px;

  &:hover {
    color: ${colors.orange};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;
