import styled from '@emotion/styled';
import { HashLink as Link } from 'react-router-hash-link';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

const StyledLink = styled(Link)`
  color: ${props => (props.active ? colors.orange : colors.textColor)};
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease;
  font-size: 17px;

  &:hover {
    color: ${colors.orange};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

export const StyledButton = styled(Link)`
  border-radius: ${colors.borderRadius};
  border: ${colors.border};
  background-color: ${colors.lightBlue};
  color: ${colors.textColor};
  cursor: pointer;
  font-weight: 700;
  font-size: 1.6rem;
  padding: 1rem 2rem;
  transition: background-color 0.3s, color 0.3s ease;
  text-decoration: none;
  z-index: 1000;

  &:hover {
    background-color: ${colors.textColor};
    color: ${colors.lightBlue};
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.5em 1em;
  }
`;

export const StyledClickButton = styled('button')`
  border-radius: ${colors.borderRadius};
  border: ${colors.border};
  background-color: ${colors.lightBlue};
  color: ${colors.textColor};
  cursor: pointer;
  font-weight: 400;
  font-size: 17px;
  padding: 1rem 2rem;
  transition: background-color 0.3s, color 0.3s ease;
  text-decoration: none;
  z-index: 1000;

  &:hover {
    background-color: ${colors.textColor};
    color: ${colors.lightBlue};
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0.5em 1em;
  }
`;

export const StyledNormalLink = styled.a`
  color: ${props => (props.active ? colors.orange : colors.textColor)};
  text-decoration: none;
  font-weight: 400;
  transition: color 0.3s ease;
  font-size: 17px;

  &:hover {
    color: ${colors.orange};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 12px;
  }
`;

export default StyledLink;
