import React from 'react'
import { Link } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import styled from '@emotion/styled'
import { useTranslation } from 'react-i18next';
import { FaqBlueText } from '../../styled-components';
import { scrollToTop } from '../../utilities/scrollToTop';
import { colors } from '../../Theme/colors';

export const DesktopFAQ = ({isSelected, options, onTabClick, questions, expanded, handleOpenPanel}) => {
  const { t } = useTranslation()

  return (
    <Container>
      <div className='tabs'>
        { options.map(({tab, questions}, index) => 
            <div 
              className={`tabs-item  ${isSelected(tab)}`}
              key={tab} 
              onClick={() => onTabClick(tab, questions, index)}
            >
              <h1>{tab}</h1>
              <div className='tabs-arrow'></div>
            </div>
        )}
        <FaqBlueText>
          { t('faq.have_questions') } <br />
          { t('faq.review_full_faq') } {' '}
          <Link to="faq" onClick={scrollToTop}>
            { t('here') }.
          </Link>
      </FaqBlueText>
      </div>
      <div>
        { questions.map( ({title, content, panel}, index) => 
          <MyAccordion expanded={expanded === panel} onChange={handleOpenPanel(panel)}  key={index}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{fontSize: 30, color: colors.lightBlue}} />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <h1>{ title }</h1>
            </AccordionSummary>
            <AccordionDetails>
              <p>{ content }</p>
            </AccordionDetails>
          </MyAccordion>
        )}
      </div>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  .tabs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: min-content;
    margin-right: 50px;

    .tabs-arrow {
      position: absolute;
      top: 15%;
      right: -30px;
      width: 0; 
      height: 0; 
      transition: border 0.3s ease;
      border-left: 30px solid transparent;
      border-top: 25px solid transparent;
      border-bottom: 25px solid transparent; 
    }

    .tabs-item {
      position: relative;
      min-width: 300px;
      transition: background-color 0.3s ease;
      border: ${colors.lightBlue} solid 3px;
      color: white;
      padding: 24px;
      
      :hover {
        background-color: ${colors.lightBlue};
      }
    }

    .on {
      background-color: ${colors.lightBlue};
      .tabs-arrow {
        border-left: 30px solid ${colors.lightBlue};
      }
    }
  }
`

const MyAccordion = styled(Accordion)`
  background-color: ${colors.background};
  color: white;
  border-bottom: white solid 3px;
  padding: 10px 10px;

  h1 {
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
`

