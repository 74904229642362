import { FaqContainer, FaqTitle } from '../styled-components';
import TabsFaq from './TabsFaq';
import { useTranslation } from 'react-i18next';

const Faq = () => {
  const {t} = useTranslation()
  return (
    <FaqContainer id="faq">
      <FaqTitle>
        {t('faq.resolve_doubts')}
        <br />
        <span>{t('faq.about_BMP')}</span>
      </FaqTitle>
      <TabsFaq />
    </FaqContainer>
  );
};

export default Faq;
