import { Widget } from '@typeform/embed-react'
import { useNavigate } from 'react-router-dom'
import { scrollToTop } from '../../../utilities/scrollToTop'



export const KYC = ({ id }) => {
  const navigate = useNavigate()

  const handleSubmit =  () => {
    setTimeout( () => {
      navigate('/', { replace: true })
      scrollToTop()
    }, 4500)
  } 

  return (
    <Widget style={{ width: '100%', height: '100vh' }} id={id} onSubmit={handleSubmit}/>
  )
}
