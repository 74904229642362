export const colors = {
  textColor: '#FFF',
  background: '#032A45',
  orange: '#FFAD62',
  lightBlue: '#00ADEF',
  lightGray: '#DEDEDE',
  darkGray: '#4C4F55',
  borderRadius: '32px',
  border: '1px solid #00ADEF',
  padding: '0px 10%',
};
