import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';
import { Typography } from '@mui/material';

export const FaqContainer = styled.div`
  padding: ${colors.padding};
  padding-top: 4rem;
  padding-bottom: 6rem;
  background-color: ${colors.background};

  @media (max-width: ${breakpoints.mobile}) {
    padding: 2rem 10% 10rem 10%;
  }
`;
export const FaqTitle = styled.h3`
  font-size: 4.2rem;
  font-weight: 700;
  color: white;
  padding-right: 30%;
  margin-bottom: 4rem;

  span {
    color: ${colors.lightBlue};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.4rem;
    padding-right: 0;
  }
`;

export const FaqTitleBlue = styled.h3`
  font-size: 4.2rem;
  font-weight: 700;
  padding-top: 4rem;
  margin-bottom: 4rem;
  color: ${colors.background};
  span {
    font-weight: 400;
    color: ${colors.background};
  }

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 2.4rem;
    padding-right: 0;
  }
`;

export const FaqQuestions = styled.span`
  font-weight: 700;
  font-size: 1.8rem;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.2rem;
  }
`;

export const FaqAnswer = styled(Typography)`
  padding: 0 2rem;
  font-size: 1.4rem;

  @media (max-width: ${breakpoints.mobile}) {
    font-size: 1.2rem;
  }
`;

export const FaqBlueText = styled.p`
  color: white;
  font-weight: 400;
  font-size: 16px;
  margin-top: 2rem;

  a {
    text-decoration: none;
    color: ${colors.orange};

    &:hover {
      text-decoration: underline;
    }
  }
`;
