import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

const AboutContainer = styled.section`
  display: flex;
  flex-direction: column;
  background-color: ${colors.background};
  color: ${colors.textColor};
  width: 100%;
  height: 100%;
  padding: ${colors.padding};
  gap: 2rem;
  padding-top: 2rem;

  h2 {
    margin-top: 3%;
    margin-bottom: 2.5%;
    font-size: 4.2rem;
    font-weight: 700;
  }

  @media (max-width: ${breakpoints.desktop}) {
    h2 {
      font-size: 2.8rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 10%;
    padding-top: 2rem;
    padding-bottom: 2rem;

    h2 {
      font-size: 2rem;
    }
  }
`;
// informacion dentro del grid con la imagen a la derecha
export const AboutGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  font-size: 1.5rem;
  gap: 2rem;
  position: relative;

  @media (max-width: ${breakpoints.largeDesktop}) {
    align-items: center;
    img {
      max-width: 80%;
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    align-items: flex-start;
  }

  @media (max-width: ${breakpoints.tablet}) {
    grid-template-columns: 1fr;
  }

  @media (max-width: ${breakpoints.mobile}) {
    img {
      max-width: 70%;
    }
  }
`;
//  texto azul dentro del grid
export const AboutBlueText = styled.p`
  color: ${colors.lightBlue};
  display: flex;
  align-items: center;
`;

// nombre y cita de Maria en position absolute
export const AboutQuote = styled.div`
  position: absolute;
  max-width: 30rem;
  font-size: 2.4rem;
  right: -4%;
  bottom: 27%;

  blockquote {
    color: ${colors.lightGray};
    font-weight: 300;
    opacity: 85%;
    font-size: 1.6rem;
  }

  @media (max-width: ${breakpoints.largerDesktop}) {
    align-items: center;
    bottom: 20rem;
    right: -12%;
  }

  @media (max-width: ${breakpoints.desktop}) {
    max-width: 23rem;
    right: -10%;
    bottom: 63%;
  }

  @media (max-width: ${breakpoints.tablet}) {
    bottom: 5rem;
    max-width: 50%;
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: calc(50% + 4rem);
    font-size: 1.4rem;
    bottom: 2rem;

    blockquote {
      font-size: 1.4rem;
    }
  }

  @media (max-width: ${breakpoints.smallMobile}) {
    font-size: 1.2rem;
    max-width: 50%;
    right: 0rem;

    blockquote {
      font-size: 1.2rem;
    }
  }
`;

export const CenterButton = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 6rem;
  margin-top: 3rem;

  @media (max-width: ${breakpoints.desktop}) {
    justify-content: center;
  }

  @media (max-width: ${breakpoints.mobile}) {
    justify-content: center;
  }
`;

export const AboutQXLogo = styled.img`
  height: 22px;
  width: 100%;

  @media (max-width: 1600px) {
    height: 22px;
  }

  @media (max-width: 1260px) {
    height: 22px;
  }

  @media (max-width: ${breakpoints.mobile}) {
    max-width: 108px;
    height: 15px;
  }
`

export default AboutContainer;
