import StyledNavbar, { NavbarLinks } from '../styled-components/Navbar';
import LogoBiva from '../assets/img/logos/logoBivaWhite.svg';
import { useEffect, useState } from 'react';
import { StyledLink } from '../styled-components';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../utilities/scrollToTop';
import MenuMobile from './MenuMobile';
import { StyledClickButton} from '../styled-components/StyledLink';
import { useTranslation } from 'react-i18next';
import { LanguagePicker } from './LanguagePicker';
import { useResponsive } from '../hook/useResponsive';

const Navbar = () => {
  const [navbarActive, setNavbarActive] = useState(false);
  const location = useLocation();
  const { t } = useTranslation()
  const { isMobileDevice } = useResponsive()
  const { hash } = location;

  const changeBackground = () => {
    if (window.scrollY >= 100) {
      setNavbarActive(true);
    } else {
      setNavbarActive(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', changeBackground);
    return () => {
      window.removeEventListener('scroll', changeBackground);
    };
  }, [navbarActive]);

  return (
    <>
      <StyledNavbar active={navbarActive}>
        { isMobileDevice ?   
            <StyledLink to='/' onClick={scrollToTop} active={hash === ' ' ? 1 : 0}>
              <img src={LogoBiva} alt='Logo' />
            </StyledLink>
          : null
        }
        <MenuMobile />
        <NavbarLinks>
          <div>
            <StyledLink to='/' onClick={scrollToTop} active={hash === ' ' ? 1 : 0}>
              <img src={LogoBiva} alt='Logo' />
            </StyledLink>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', gap: '2.5rem', alignItems: 'center'}}>
            <StyledLink
              smooth
              to='/'
              onClick={scrollToTop}
              active={hash === 'inicio' || hash === '' ? 1 : 0}
            >
              {t('navbar.home')}
            </StyledLink>
            <StyledLink smooth to='#about' active={hash === '#about' ? 1 : 0}>
              {t('navbar.we')}
            </StyledLink>
            <StyledLink smooth to='#faq' active={hash === '#faq' ? 1 : 0}>
              {t('navbar.FAQ')}  
            </StyledLink>
          </div>
          <div style={{display: 'flex', justifyContent: 'center', gap: '2.5rem', alignItems: 'center'}}>
            <StyledLink
              smooth
              to='#registrate'
              active={hash === '#registrate' ? 1 : 0}
            >
              {t('navbar.register')}
            </StyledLink>
            <StyledClickButton onClick={() => window.open('http://stage.biva.mx.s3-website.us-east-2.amazonaws.com/biva')}>
               {t('navbar.access')}
            </StyledClickButton>
            <LanguagePicker />
          </div>
        </NavbarLinks>
      </StyledNavbar>
    </>
  );
};

export default Navbar;
