import StyledNavbar from '../styled-components/Navbar';
import LogoBiva from '../assets/img/logos/logoBivaWhite.svg';
import { StyledLink } from '../styled-components';
import { useLocation } from 'react-router-dom';
import { scrollToTop } from '../utilities/scrollToTop';

const NavbarOnlyLogo = () => {
  const location = useLocation();
  const { hash } = location;

  return (
    <>
      <StyledNavbar active={1}>
        <StyledLink to="/" onClick={scrollToTop} active={hash === ' ' ? 1 : 0}>
          <img src={LogoBiva} alt="Logo" />
        </StyledLink>
      </StyledNavbar>
    </>
  );
};

export default NavbarOnlyLogo;
