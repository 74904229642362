import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

export const CallToActionContainer = styled.div`
  padding: 2rem 10%;
  background-color: #EAEAEA;
  width: 100%;
  color: ${colors.textColor};

  h4 {
    padding: 4rem 0 2rem 0;
    font-size: 3.2rem;
    font-weight: 700;
  }

  @media (max-width: ${breakpoints.desktop}) {
    h4 {
      font-size: 2.4rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 5%;
    text-align: center;
    h4 {
      font-size: 1.6rem;
      padding: 4rem 0 2rem 0;
    }
  }
`;
