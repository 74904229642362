import styled from '@emotion/styled';
import { Trans, useTranslation } from 'react-i18next';
import {
  Button,
  ButtonOutline,
  CallToActionContainer,
} from '../styled-components';
import { colors } from '../Theme/colors';
import { scrollToTop } from '../utilities/scrollToTop';

const DivCallToAction = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem;
  padding-bottom: 6rem;
  padding-top: 2rem;
  align-items: center;

  h2 {
    text-transform: uppercase;
  }

  @media (max-width: 480px) {
    grid-template-columns: 1fr;

  }

  section {
    display: flex;
    gap: 10px;

    @media (max-width: 480px) {
      justify-content: center;
    }
  }
`;

const CallToAction = () => {
  const {t} = useTranslation()
  return (
    <CallToActionContainer id='registrate'>
      <Trans
        i18nKey='register_now'
        components={{
          h4: <h4 style={{fontWeight: 400, color: colors.background}} />,
          strong: <strong />
        }}
      />
      <DivCallToAction>
        <section>
          <ButtonOutline to='/empresa' onClick={scrollToTop}>
          { t('i_am_company') }
          </ButtonOutline>
          <Button to='/inversionista' onClick={scrollToTop}>
          { t('i_want_invest') }
          </Button>
        </section>
        <div style={{ justifySelf: 'flex-end', color: colors.background }}>
          <h2>{t('contact_us')} T. (55) 4166 3300 | contacto@bivamercadoprivado.com</h2>
        </div>
      </DivCallToAction>
    </CallToActionContainer>
  );
};

export default CallToAction;
