import { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Spin as Hamburger } from 'hamburger-react';
import { colors } from '../Theme/colors';
import {
  HamburguerIcon,
  Menu,
  StyledLinkMobile,
} from '../styled-components/Menu';
import { scrollToTop } from '../utilities/scrollToTop';
import { StyledNormalLink } from '../styled-components/StyledLink';
import { useTranslation } from 'react-i18next';
import { LanguagePicker } from './LanguagePicker';

const MenuMobile = () => {
  const [active, setActive] = useState(false);
  const {t} = useTranslation()
  const location = useLocation();
  const { hash } = location;
  const { lightBlue } = colors;

  return (
    <HamburguerIcon menuActive={active}>
      <LanguagePicker />
      <Hamburger
        toggled={active}
        toggle={setActive}
        color={lightBlue}
        label='Show menu'
        // onClick={() => setActive(prev => !prev)}
      />
      <Menu menuActive={active}>
        <StyledLinkMobile
          smooth
          to={'/'}
          onClick={scrollToTop}
          active={hash === 'inicio' || hash === '' ? 1 : 0}
        >
          {t('navbar.home')}
        </StyledLinkMobile>
        <StyledLinkMobile
          smooth
          to={'#about'}
          active={hash === '#about' ? 1 : 0}
        >
          {t('navbar.we')}
        </StyledLinkMobile>
        <StyledLinkMobile smooth to={'#faq'} active={hash === '#faq' ? 1 : 0}>
          {t('navbar.FAQ')}  
        </StyledLinkMobile>
        <StyledLinkMobile
          smooth
          to={'#registrate'}
          active={hash === '#registrate' ? 1 : 0}
        >
          {t('navbar.register')}
        </StyledLinkMobile>
        <StyledNormalLink
          href='http://stage.biva.mx.s3-website.us-east-2.amazonaws.com/biva'
          target='_blank'
          rel='noreferrer'
        >
          {t('navbar.access')}
        </StyledNormalLink>
      </Menu>
    </HamburguerIcon>
  );
};

export default MenuMobile;
