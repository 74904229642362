import styled from '@emotion/styled'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import { FaqBlueText } from '../../styled-components'
import { colors } from '../../Theme/colors'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { scrollToTop } from '../../utilities/scrollToTop'
import { useTranslation } from 'react-i18next'
import { useState } from 'react'

export const MobileFAQ = ({isSelected, options, onTabClick, expanded, handleOpenPanel}) => {
  const { t } = useTranslation()
  const [ tabExpanded, setTabExpanded ] = useState(0)

  return (
    <Container>
      <div className='tabs'>
        { options.map(({tab, questions}, index) => 
          <div 
            key={tab} 
            onClick={() => {
              setTabExpanded(index)
              onTabClick(tab, questions, index)
            }}
          >
            <div className={`tabs-item  ${isSelected(tab)}`}>
              <h1>{tab}</h1>
            </div>
            {console.log(tabExpanded, index)}
            { tabExpanded === index ?
              questions.map( ({title, content, panel}, index) => 
                <MyAccordion expanded={expanded === panel} onChange={handleOpenPanel(panel)}  key={index}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon sx={{fontSize: 30, color: colors.lightBlue}} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <h1>{ title }</h1>
                  </AccordionSummary>
                  <AccordionDetails>
                    <p>{ content }</p>
                  </AccordionDetails>
                </MyAccordion>
              )
              : null
            }
          </div>
        )}
      </div>
      <FaqBlueText>
          { t('faq.have_questions') } <br />
          { t('faq.review_full_faq') } {' '}
          <Link to="faq" onClick={scrollToTop}>
            { t('here') }.
          </Link>
      </FaqBlueText>
    </Container>
  )
}

const Container = styled.div`
  .tabs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin-right: 50px;

    .tabs-item {
      position: relative;
      min-width: 300px;
      transition: background-color 0.3s ease;
      border: ${colors.lightBlue} solid 3px;
      color: white;
      padding: 24px;
      
      :hover {
        background-color: ${colors.lightBlue};
      }
    }

    .on {
      background-color: ${colors.lightBlue};
    }
  }
`

const MyAccordion = styled(Accordion)`
  background-color: ${colors.background};
  color: white;
  border-bottom: white solid 3px;
  padding: 10px 10px;

  h1 {
    font-size: 16px;
    font-weight: 700;
  }

  p {
    font-size: 14px;
    font-weight: 400;
  }
`
