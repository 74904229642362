import {
  AboutContainer,
  Button,
  ButtonOutline,
  CardSectionContainer,
  AboutBlueText,
  AboutGrid,
  AboutQuote,
  CenterButton,
  CardDescriptionContainer,
  CardQuantum,
  AboutQXLogo,
} from '../styled-components';
import ArizaFoto from '../assets/img/images/ArizaFoto.png';
import { IoIosArrowForward } from 'react-icons/io';
import { IconContext } from 'react-icons';
import { colors } from '../Theme/colors';
import { motion } from 'framer-motion';
import { scrollToTop } from '../utilities/scrollToTop';
import CardSteps from './CardSteps';
import {useCardsData} from '../data/cardsData'
import { animationDuration } from '../Theme/animationDuration';
import qxLogo from '../assets/img/logos/logo-qx-horizontal.png';
import { Trans, useTranslation } from 'react-i18next';

const { oneSecond } = animationDuration;

const AboutUs = () => {
  const cardsData = useCardsData()
  const {t} = useTranslation()
  return (
    <IconContext.Provider value={{ color: '#00ADEF', size: '1.5em' }}>
      <AboutContainer id="about">
        <h2>{t('about_us.title')}</h2>
        <AboutGrid>
          <div>
            <motion.p
              style={{ marginBottom: '2rem', fontWeight: '300' }}
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: oneSecond,
                delay: 0.4,
              }}
              viewport={{ once: true }}
            >{t('about_us.paragraph_one')} </motion.p>
            <motion.p
              style={{
                color: `${colors.lightGray}`,
                opacity: '60%',
                marginBottom: '4rem',
                fontWeight: '300',
              }}
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: oneSecond,
                delay: 0.5,
              }}
              viewport={{ once: true }}
            >{t('about_us.paragraph_two')} </motion.p>
            <motion.p
              style={{
                color: `${colors.lightGray}`,
                opacity: '60%',
                marginBottom: '4rem',
                fontWeight: '300',
              }}
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: oneSecond,
                delay: 0.5,
              }}
              viewport={{ once: true }}
            >{t('about_us.paragraph_three')} </motion.p>
            <AboutBlueText
              as={motion.p}
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: oneSecond,
                delay: 0.6,
              }}
              viewport={{ once: true }}
            >
              <IoIosArrowForward />
              {t('about_us.for_investors')}
            </AboutBlueText>
            <motion.p
              style={{ fontWeight: '300', paddingLeft: '1.5em' }}
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: oneSecond,
                delay: 0.7,
              }}
              viewport={{ once: true }}
            >
              {t('about_us.investor_label')}
            </motion.p>
            {/* Empresas */}
            <AboutBlueText
              style={{ marginTop: '3rem' }}
              as={motion.p}
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: oneSecond,
                delay: 0.8,
              }}
              viewport={{ once: true }}
            >
              {' '}
              <IoIosArrowForward />
              {t('about_us.for_companies')}
            </AboutBlueText>
            <motion.p
              style={{
                fontWeight: '300',
                paddingLeft: '1.5em',
                marginBottom: '2rem',
              }}
              initial={{ opacity: 0, translateY: -50 }}
              whileInView={{ opacity: 1, translateY: 0 }}
              transition={{
                duration: oneSecond,
                delay: 0.9,
              }}
              viewport={{ once: true }}
            >{t('about_us.company_label')}</motion.p>
          </div>
          <div style={{ justifySelf: 'center' }}>
            <AboutQuote>
              <motion.p
                initial={{ opacity: 0, translateX: 50 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{
                  duration: oneSecond,
                  delay: 1,
                }}
                viewport={{ once: true }}
              >
                <strong><h5>{t('about_us.maria_ariza')}</h5></strong>
                <h6 style={{marginBottom: 8}}>CEO BIVA</h6>
              </motion.p>
              <motion.blockquote
                initial={{ opacity: 0, translateX: 50, zIndex: 10 }}
                whileInView={{ opacity: 1, translateX: 0 }}
                transition={{
                  duration: oneSecond,
                  delay: 1,
                }}
                viewport={{ once: true }}
              >
                {t('about_us.maria_ariza_opinion')}
              </motion.blockquote>
            </AboutQuote>

            <motion.img
              src={ArizaFoto}
              alt="Maria Ariza"
              initial={{ opacity: 0, translateX: 50, zIndex: -1 }}
              whileInView={{ opacity: 1, translateX: 0 }}
              transition={{
                duration: oneSecond,
                delay: 1.1,
              }}
              viewport={{ once: true }}
            />
          </div>
        </AboutGrid>

        <CenterButton>
          <ButtonOutline to="/empresa" onClick={scrollToTop}>
          {t('i_am_company')}
          </ButtonOutline>
          <Button to="/inversionista" onClick={scrollToTop}>
          {t('i_want_invest')}
          </Button>
        </CenterButton>
        <h2 id="comoFunciona">  {t('about_us.how_works')}</h2>
      </AboutContainer>
      <CardSectionContainer>
        {cardsData.map(card => {
          return (
            <CardSteps
              key={card.stepNumber}
              {...card}
            />
          );
        })}
      </CardSectionContainer>
      <CardDescriptionContainer>
        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 14 }}>
          <Trans 
            i18nKey='about_us.disclaimer_one'
            components={{em: <em />, p: <p />}}
          />
        </div>
        <CardQuantum>
          <p>{t('about_us.disclaimer_qx')}</p>
          <a target={'_blank'} href="https://quantum-x.io/" rel="noreferrer">
            <AboutQXLogo src={qxLogo} alt="Logo"  />
          </a>
        </CardQuantum>
      </CardDescriptionContainer>
    </IconContext.Provider>
  );
};

export default AboutUs;
