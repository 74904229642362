import styled from '@emotion/styled';
import breakpoints from '../Theme/breakpoints';
import { colors } from '../Theme/colors';

const MainContainer = styled.main`
  align-items: flex-start;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  color: ${colors.textColor};
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
  padding: ${colors.padding};
  position: relative;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 10rem;
    background: linear-gradient(
      to top,
      ${colors.background} 22%,
      transparent 85%
    );
  }

  &::before {
    content: '';
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(to top, black 75%, transparent 152%);
    opacity: 75%;
    top: 0;
    left: 0;
    z-index: 0;
  }

  p {
    margin-top: 4rem;
    margin-bottom: 2rem;
    font-size: 2.4rem;
    z-index: 1;
  }

  h1 {
    margin-top: 20vh;
    color: ${colors.textColor};
    font-weight: 700;
    font-size: 6.8rem;
    z-index: 1;

    span {
      font-weight: 400;
    }
  }

  #h1 {
    margin-top: 0;
    color: ${colors.textColor};
    font-size: 6.8rem;
    font-weight: 400;
  }

  @media (max-width: ${breakpoints.largerDesktop}) {
    h1,
    #h1 {
      font-size: 4.2rem;
    }

    p {
      font-size: 2rem;
    }
  }

  @media (max-width: ${breakpoints.desktop}) {
    background-position: center;
  }

  @media (max-width: ${breakpoints.tablet}) {
    h1,
    #h1 {
      font-size: 2.6rem;
    }

    p {
      font-size: 1.4rem;
    }
  }

  @media (max-width: ${breakpoints.mobile}) {
    padding: 0 10%;
  }
`;

export const VideoOverlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;

  &::after {
    content: '';
    position: absolute;
    width: 100%;
    min-height: 100vh;
    background: linear-gradient(to top, black 75%, transparent 152%);
    opacity: 45%;
    top: 0;
    left: 0;
  }
`;

export default MainContainer;
